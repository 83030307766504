export const countryMixin = {
    methods: {
        country_countryToObject (country) {
            if (country === null) {
                return {
                    country: 'no country',
                    abbrev: ''
                }
            }
            let tmpCountry = country.trim().toLowerCase();
            switch(tmpCountry) {
                case 'it':
                case 'italy':
                    return {
                        country: 'Italy',
                        language: 'Italian',
                        abbrev: 'it'
                    }
                case 'en':
                case 'england':
                    return {
                        country: 'England',
                        language: 'English',
                        abbrev: 'en'
                    }
                case 'germany':
                case 'de':
                    return {
                        country: 'Germany',
                        language: 'German',
                        abbrev: 'de'
                    }
                case 'at':
                case 'austria':
                    return {
                        country: 'Austria',
                        language: 'German',
                        abbrev: 'at'
                    }
                case 'fr':
                case 'france':
                    return {
                        country: 'France',
                        language: 'French',
                        abbrev: 'fr'
                    }
                case 'ad':
                case 'andorra':
                    return {
                        country: 'Andorra',
                        language: 'Catalan',
                        abbrev: 'ad'
                    }
                case 'ch':
                case 'switzerland':
                    return {
                        country: 'Switzerland',
                        language: 'German',
                        abbrev: 'ch'
                    }
                case 'no':
                case 'norway':
                    return {
                        country: 'Norway',
                        language: 'Norwegian',
                        abbrev: 'no'
                    }
                case 'sc':
                case 'slovakia':
                    return {
                        country: 'Slovakia',
                        language: 'Slovak',
                        abbrev: 'sc'
                    }
                case 'tt':
                case 'turkey':
                    return {
                        country: 'Turkey',
                        language: 'Turkish',
                        abbrev: 'tt'
                    }
                case 'es':
                case 'spain':
                    return {
                        country: 'Spain',
                        language: 'Spanish',
                        abbrev: 'es'
                    }
                case 'ru':
                case 'russia':
                    return {
                        country: 'Russia',
                        language: 'Russian',
                        abbrev: 'ru'
                    }
                default:
                    return {
                        country: country,
                        abbrev: ''
                    }
            }
        }
    }
}