<template>
  <!--
    A select to choose the websites language from.
  -->
  <div class="languageSelect">
    <select
      v-model="$i18n.locale"
      class="form-control m-nav__link verticalAlignMiddle cursor-pointer pr-0 pt-2 pb-2 pl-2"
      @change="saveSelectedLanguage($event)"
    >
      <option
        v-for="(lang, i) in languages"
        :key="`Lang${i}`"
        :value="lang"
      >
        {{ country_countryToObject(lang).language }}
      </option>
    </select>
  </div>
</template>

<script>
import { countryMixin } from '@/mixins/countryMixin.js';

export default {
  name: "LanguageSelect",
  mixins: [
    countryMixin
  ],
  computed: {
    languages () {
      return Object.keys(this.$i18n.messages);
    }
  },
  methods: {
    saveSelectedLanguage (event) {
      localStorage.setItem('alturosSpaLanguage', event.target.value);
    }
  }
}
</script>
